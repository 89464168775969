// Colors
$siteTextColor: #000000;

$white: #ffffff;
$white100: #f7f7f7;
$white200: #f0f0f0;
$white300: #dfdfdf;
$white400: #fdfdfd;
$white500: #ededed;
$white600: #e2e2e2;
$white700: #b2b2b2;
$white800: #d8d8d8;
$white900: #fafafa;
$white1000: #dee2e8;
$white1100: #eaeaea;
$white1200: #787878;
$white1300: #b9b9b9;
$white1400: #ececec;
$white1500: #a8aaac;

$black: #000000;
$black100: #1a212c;
$black200: #1e1e1e;
$black300: #303030;
$black400: #4d4d4d;
$black500: #7f7f7f;
$black600: #efefef;
$black700: #d6d6d6;
$black800: #c2c2c2;
$black900: #999999;
$black1000: #5a5a5a;
$black1100: #0d0d0d;
$black1200: #595959;

$grey100: #f2f4f7;
$grey200: #d6d8e3;
$grey300: #cccccc;
$grey400: #e5e5e5;
$grey500: #949798;
$grey600: #979797;
$grey700: #c9c9c9;
$grey800: #7d9aaa;
$grey900: #96a4b7;
$grey1000: #a7a7a7;
$grey1100: #b3b3b3;
$grey1200: #c7c7c7;
$grey1300: #c7d6db;
$grey1400: #545454;
$grey1500: #f8f8f8;
$grey1600: #d0d6de;
$grey1700: #f1f5f7;
$grey1800: #d7dce2;
$grey1900: #5c5c5c;
$grey2000: #979797;
$grey2100: #6d7278;
$grey2200: #d7d7d7;
$grey2300: #4a4a4a;
$grey2400: #818181;
$grey2500: #d8dde4;
$grey2600: #f2f2f2;
$grey2700: #4d4d4d;
$grey2800: #777777;
$grey2900: #e6eaea;
$grey3000: #dbdfe5;

$blue100: #148fa7;
$blue200: #009aa6;
$blue300: #e4effe;
$blue400: #4b93fd;
$blue500: #00a9f3;
$blue600: #325dff;
$blue700: #2251ff;

$orange100: #fbdedb;
$yellow100: #ffd800;
$yellow300: #e6d7bc;

$green100: #9fb9bd;
$green200: #08d39c;
$green300: #6d992c;
$green400: #b5fcc1;
$green500: #61c8a6;
$green600: #e6faf5;
$green700: #00d39c;
$green800: #4bca7a;
$green1000: #10cbbc;

$darkBlue100: #002960;
$darkBlue200: #002768;
$darkBlue300: #002864;
$darkBlue400: #1e41e6;
$darkBlue500: #002960;

$lightBlue100: #e9eff4;
$lightBlue200: #4b93fd;
$lightBlue300: #1976d2;

$red100: #ff3b4c;
$red200: #ad005b;
$red300: #9f0554;
$red400: #992c2c;
$red500: #cd3030;

$pink100: #ffe5ea;
$purple100: #9d6cda;
$yellow100: #ffdb77;
$yellow400: #ff9900;
$cohesionPractice: (
    high: #e5546c,
    moderate: #5fceff,
    optimal: #00b050,
    low: #7030a0,
);
$cohesionPracticeOhi4: (
    high: #061f79,
    moderate: #00a9f4,
    optimal: #2251ff,
    low: #99c4ff,
);

$LightBlack: #333333;
$LightYellow: #fff9ef;
