@import 'variables';
@import 'functions';

html {
    height: 100%;
    font-size: rem(1.6);
    body {
        box-sizing: border-box;
        min-height: 100%;
        font-size: 1rem;
        font-family: 'TheinhardtPan';

        margin: 0;
        background: $white;
        color: $siteTextColor;
        font-weight: 400;
        line-height: 1.43;

        & > iframe {
            display: none !important;
        }

        #root {
            height: 100%;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        * {
            box-sizing: border-box;
        }

        .ellipsisPro {
            overflow: hidden;
            word-wrap: break-word;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }

        .clearfix {
            &:after {
                visibility: hidden;
                display: block;
                font-size: 0;
                content: ' ';
                clear: both;
                height: 0;
            }
        }
    }
}
