@import '../../sass/variables';
@import '../../sass/functions';

.ohiWrapper {
    height: rem(100);
    position: relative;
    background-image: url('./assets/loginPageBg.jpg');
    // background-image: url('./assets/bg_login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .loader {
        background: transparent !important;
    }

    .loginWrapper {
        background: $white;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -35%);
        padding: rem(8) rew(4);
        border-radius: 8px;
        text-align: center;

        .loginHead {
            margin-bottom: rem(2);
        }

        .loginButton {
            padding: 0 rew(3);
            background: $green600;
            border-radius: 12px;
            font-size: rem(2);
            cursor: pointer;
            line-height: rem(8);

            img {
                position: relative;
                margin-right: rew(1);
                top: 3px;
            }

            & > span {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    .ohiLoginContent {
        padding: 3vh 2vw 2vh 3vw;
        border-bottom: 1px solid #103559;
    }

    .loginSection {
        display: flex;
        height: 72vh;
        .loginHeading {
            display: flex;
            align-items: center;
            height: 58vh;
            width: 100vw;
            justify-content: flex-start;
            .loginContent {
                margin-left: rem(10);
                .subHead {
                    color: #fff;
                    font-family: Bower;
                    font-size: rem(7);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.75rem;
                }
                .loginButton {
                    display: flex;
                    padding: rem(1.5) rem(1);
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    width: 20%;
                    border-radius: 4px;
                    background: #ffefd1;
                    margin-top: 5vh;
                    cursor: pointer;
                    .btn {
                        color: #051c2c;
                        text-align: center;
                        font-family: 'Mckinsey Sans';
                        font-size: rem(1.8);
                        font-style: normal;
                        font-weight: 400;
                    }
                    &:hover {
                        background: #ffe4b3;
                    }
                }
            }
        }

        .starContent {
            display: flex;
            align-items: flex-end;
            height: 57vh;
            justify-content: flex-end;
        }

        .loginImgContent {
            align-items: flex-end;
            width: 34vw;
            display: flex;
            justify-content: flex-end;
            height: 85vh;
            img {
                width: 45vw;
                height: 61vh;
            }
        }

        .starContent2 {
            display: flex;
            align-items: flex-end;
            height: 53vh;
            width: 2px;
            justify-content: flex-end;
            img {
                padding-right: rem(2);
            }
        }
    }

    .footerLogo {
        display: inline-flex;
        margin-left: rem(10);
    }

    .loginPageText {
        gap: 10px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin: rem(4) rem(10);
        .loginText {
            color: #757575;
            font-family: 'McKinsey Sans';
            font-size: rem(2);
            font-style: normal;
            font-weight: 300;
            line-height: 1.5rem;
        }
        .loginCloudLink {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: rem(2);
            img {
                width: rem(2);
                color: black;
                margin-top: rem(0.4);
            }
            a {
                color: black;
                cursor: pointer;
            }
            .loginCloudText {
                color: #333;
                font-family: 'McKinsey Sans';
                font-size: rem(2);
                font-style: normal;
                font-weight: 300;
                // line-height: 1.5rem;
                margin-top: rem(0.4);
            }
        }
    }
}
