@font-face {
    font-family: 'TheinhardtPan';
    src: url('assets/fonts/TheinhardtPan-Regular.otf');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'TheinhardtPan';
    src: url('assets/fonts/TheinhardtPan-Italic.otf');
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: 'TheinhardtPan';
    src: url('assets/fonts/TheinhardtPan-LightItalic.otf');
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: 'TheinhardtPan';
    src: url('assets/fonts/TheinhardtPan-Medium.otf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'TheinhardtPan';
    src: url('assets/fonts/TheinhardtPan-MediumItalic.otf');
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: 'TheinhardtPan';
    src: url('assets/fonts/TheinhardtPan-Light.otf');
    font-style: normal;
    font-weight: 200;
}
