@import '../../../../sass/variables';
@import '../../../../sass/functions';

%message-shared {
    color: $black300;
    border: 1px solid $black1000;
    position: relative;
    border-radius: 4px;
    margin-bottom: 0.6vh;
    padding: 0 rem(2);
    cursor: pointer;
    line-height: normal;
}

// Table CSS

%table-head-nSize {
    text-align: center;
    max-width: 10vh;
    width: 10vh;
    line-height: normal;
    word-break: break-word;
}
.custom-bu-table-wrapper {
    margin: rem(4) 0 rem(3) 0;
    .colmn-header {
        @extend %table-head-nSize;
        font-size: 2vh;
        font-weight: 500;
        vertical-align: bottom;
        > span {
            display: block;
            text-align: center;
            color: $grey2000;
        }
    }
    .sectionListWrapper {
        height: 5vh;
        width: 18vh;
        margin-left: 1vh;
        &.recordName {
            @extend %message-shared;
            text-align: left;
        }
        &.scroreData {
            @extend %message-shared;
            background: rgb(18, 143, 167);
            color: rgb(255, 255, 255);
            border: none;
            font-size: 2vh;
            padding: 0 4vh;
            &.top-decile {
                border: 2px solid;
                border-color: $yellow400;
            }
            .score-diff {
                position: absolute;
                padding: 0.5vh;
                width: 1.45vw;
                height: 2.8vh;
                background-color: #fff;
                text-align: center;
                line-height: normal;
                border-radius: 50%;
                font-size: 1.3vh;
                right: 0.5vh;
                color: $black;
            }
        }
    }
}
